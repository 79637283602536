/* eslint-disable react/prop-types */
import React, { FunctionComponent, useEffect, useMemo, useState, useContext } from 'react';
import { faChevronRight, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { format } from 'date-fns';
import carImage from '../../../Assets/Images/noBackground.png';
import { IDefaultJob } from '../../../Shared/Interfaces';
import { AuthContext } from '../../../Providers/UserProvider';
import newDate from '../../../utils/newDate';

interface Props {
    job: IDefaultJob;
    goToDetails: (text: string) => void;
}

const WorkOrderComponent: FunctionComponent<Props> = ({ job, goToDetails }) => {
    const { shopsRef } = useContext(AuthContext);

    const shopID = useMemo(() => job?.shop, [job?.shop]);

    const [shop, setShop] = useState<any>({});

    useEffect(() => {
        if (shopID) {
            shopsRef
                .doc(shopID)
                .get()
                .then((snapshot) => {
                    setShop(snapshot.data());
                });
        }
    }, [setShop, shopID, shopsRef]);

    const btnGroupClasses = classNames(
        ' text-white flex space-x-1 border-t-2 border-b-2 pt-4 pb-4 items-center sm:justify-center text-left ',
        {
            'border-yellow-600 bg-yellow-500': job.status === 'pending',
            'border-green-600 bg-green-500': job.status === 'active',
            'border-blue-600 bg-blue-500': job.status === 'complete',
            'border-purple-600 bg-purple-500': job.status === 'rejected',
            'border-red-600 bg-red-500': job.status === 'cancelled',
        },
    );

    return (
        <div className="pt-3">
            <button type="button" className="w-full focus:outline-none" onClick={() => goToDetails(job.id)}>
                <div className={btnGroupClasses}>
                    <div className="flex-auto md:flex-none">
                        <img className="max-w-xxs sm:max-w-md" src={carImage} alt="Tech Scheduler Car" />
                    </div>
                    <div className="flex-auto md:flex-none">
                        <p className="text-xs">
                            {job.vehicle.make} {job.vehicle.model}
                        </p>
                        <p className="text-xl font-semibold">
                            {job.type}
                            {job.damage ? ` for ${job.damage}` : ''} at {shop.name}
                        </p>
                        <p className="text-xs">Available to pick up: {job.waiting ? 'yes ' : 'no '}</p>
                        <p className="text-xs">{format(newDate(job.date), 'MM/dd/yyyy h:mm a')}</p>
                        {shop.phone && (
                            <p className="text-base mt-2">
                                <FontAwesomeIcon className="mr-1" icon={faPhone} />
                                {shop.phone}
                            </p>
                        )}
                    </div>
                    <div className="flex-none">
                        <FontAwesomeIcon
                            className="hover:text-blue-400 text-2xl text-white-400 md:ml-6 md:text-4xl mr-1"
                            icon={faChevronRight}
                        />
                    </div>
                </div>
            </button>
        </div>
    );
};

export default WorkOrderComponent;
