import React, { useCallback, FunctionComponent, useState, useEffect, useMemo, useContext } from 'react';
import { faChevronLeft, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import Car from '../../../../Assets/Images/detailsCar.png';
import CarComplete from '../../../../Assets/Images/detailsCarComplete.png';
import { db, firebase } from '../../../../firebase';
import { AuthContext } from '../../../../Providers/UserProvider';
import newDate from '../../../../utils/newDate';

interface Props {
    jobDetailsId: string;
    onClose: () => void;
}

const AcceptedJobModal: FunctionComponent<Props> = (Props) => {
    const { techId, tech, jobsRef, shopsRef, techsRef } = useContext(AuthContext);

    const closeModal = useCallback(() => {
        Props.onClose();
    }, [Props]);

    const jobId = Props.jobDetailsId;
    const [jobDetail, setJobDetail] = useState<any>({});
    const [shopAddress, setShopAddress] = useState<any>({});
    const [shopPhone, setShopPhone] = useState<any>('');
    const [rejectedNotes, setRejectedNotes] = useState<any>('not specified');

    useEffect(() => {
        return jobsRef.doc(jobId).onSnapshot((documentSnapshot) => {
            setJobDetail(documentSnapshot.data());
        });
    }, [jobId, jobsRef]);

    const completeJobs = useMemo(() => {
        return jobDetail;
    }, [jobDetail]);

    useEffect(() => {
        return shopsRef.doc(completeJobs.shop).onSnapshot((documentSnapshot) => {
            const shop = documentSnapshot.data();
            setShopAddress(shop?.address);
            setShopPhone(shop.phone);
        });
    }, [completeJobs.shop, shopsRef]);

    const rejectedJobs: Array<{ id: string; status: string }> = useMemo(() => {
        return tech.rejectedJobs;
    }, [tech]);

    const acceptWorkOrder = () => {
        const batch = db.batch();

        const currentRejectedJobs = (rejectedJobs || []).filter((job) => job.id !== jobId);

        batch.update(jobsRef.doc(jobId), {
            status: 'active',
            rejectedBy: (jobDetail?.rejectedBy ?? []).filter((jobTechId) => jobTechId !== techId),
        });
        batch.update(techsRef.doc(techId), {
            rejectedJobs: currentRejectedJobs,
        });

        batch.commit();
    };

    const completeWorkOrder = () =>
        jobsRef.doc(jobId).update({
            status: 'complete',
        });

    const rejectWorkOrder = (jobsInformation) => {
        const batch = db.batch();

        const currentRejectedJobs = (rejectedJobs || []).filter((job) => job.id !== jobId);

        batch.update(techsRef.doc(techId), {
            rejectedJobs: currentRejectedJobs,
        });

        batch.update(techsRef.doc(techId), {
            rejectedJobs: firebase.firestore.FieldValue.arrayUnion({
                ...jobsInformation,
                status: 'rejected',
                id: jobId,
            }),
        });

        batch.update(techsRef.doc(techId), {
            jobs: firebase.firestore.FieldValue.arrayRemove(jobId),
        });

        const rejectedBy: Array<string> = (jobsInformation?.rejectedBy ?? []).concat(techId);
        batch.update(jobsRef.doc(jobId), {
            assignee: '',
            notes: `${jobDetail.notes} Rejected by ${tech.name}: ${rejectedNotes}.`,
            rejectedBy: [...new Set(rejectedBy)],
        });

        batch.commit();
        Props.onClose();
    };

    const onChangeHandler = (setFunc) => (evt) => setFunc(evt.currentTarget.value);

    return (
        <>
            <div className="fixed w-full h-full top-0 left-0 flex z-50 ">
                <div
                    className="fixed w-full overflow-y-scroll bg-details-background bg-cover pb-12"
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{ height: '100%' }}
                >
                    <div className="fixed justify-start p-2 border-b border-solid border-gray-300 bg-gray-100 w-full">
                        <button
                            className="text-blue-500 background-transparent font-bold text-sm outline-none focus:outline-none"
                            type="button"
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon className="text-base mr-1" icon={faChevronLeft} />
                            <span>Back</span>
                        </button>
                    </div>
                    <div className="pt-14 container mx-auto h-auto text-left pb-4">
                        <div className="flex flex-col items-center">
                            <div className="uppercase text-xl font-medium px-3">
                                {jobDetail.status} {jobDetail.type} Job
                            </div>
                            <div className="text-3xl">
                                {jobDetail.vehicle?.year} {jobDetail.vehicle?.make} {jobDetail.vehicle?.model}
                            </div>
                            <div className="mt-2 mx-4 text-sm text-gray-600 uppercase">
                                {shopAddress?.street}, {shopAddress?.city}, {shopAddress?.state}
                            </div>
                            {shopPhone && (
                                <div className="mt-2 mx-4 text-lg text-blue-500 uppercase">
                                    <a href={`tel:+1${shopPhone}`}>
                                        <FontAwesomeIcon className="mr-1" icon={faPhone} />
                                        {shopPhone}
                                    </a>
                                </div>
                            )}
                            <div className="mt-2 md:max-w-lg max-w-sm">
                                <img
                                    src={jobDetail.status === 'complete' ? CarComplete : Car}
                                    alt="Tech Scheduler Car"
                                />
                            </div>
                            <div className="mt-4 text-base text-gray-600 text-center">
                                <p className="my-1">Insurance: {jobDetail.vehicle?.insurance}</p>
                                <p className="my-1">Contact: {jobDetail.contact}</p>
                                <p className="my-1">
                                    Date: {jobDetail.date && format(newDate(jobDetail.date), 'MM/dd/yyyy h:mm a')}
                                </p>
                                <p className="my-1">Status: {jobDetail.dropped}</p>
                                <p className="my-1">
                                    {jobDetail.status === 'complete' &&
                                        (jobDetail.waiting ? 'Available to pick up' : 'Not available to pick up')}
                                </p>
                                <p className="my-1 max-w-xl">Notes: {jobDetail.notes}</p>
                            </div>
                            <div className="mt-4">
                                {jobDetail.status === 'pending' ? (
                                    <button
                                        type="button"
                                        className="bg-green-400 hover:bg-green-900 text-white font-bold py-3 px-4 rounded w-72"
                                        onClick={acceptWorkOrder}
                                    >
                                        Accept Job
                                    </button>
                                ) : null}
                                {jobDetail.status === 'active' ? (
                                    <button
                                        type="button"
                                        className="bg-green-400 hover:bg-green-900 text-white font-bold py-3 px-4 rounded w-72"
                                        onClick={completeWorkOrder}
                                    >
                                        Complete Job
                                    </button>
                                ) : null}
                                {jobDetail.status === 'complete' ? (
                                    <button
                                        type="button"
                                        disabled
                                        className="bg-gray-200 hover:bg-gray-200 text-white font-bold py-3 px-4 rounded w-72"
                                    >
                                        Job Complete
                                    </button>
                                ) : null}
                            </div>
                            <div className="mt-8 mb-4 text-center">
                                {jobDetail.status === 'pending' && (
                                    <div className="flex flex-col">
                                        <button
                                            type="button"
                                            className="bg-red-400 hover:bg-red-900 text-white font-bold py-3 px-4 rounded w-72"
                                            onClick={() => rejectWorkOrder(jobDetail)}
                                        >
                                            Reject Job
                                        </button>
                                        <input
                                            type="text"
                                            placeholder="Reason for rejection"
                                            onChange={onChangeHandler(setRejectedNotes)}
                                            className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcceptedJobModal;
