import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyBGc8AqyIrXVMpqcLNnooayoLqbk4fSr-E',
    authDomain: 'techrepair-glass.firebaseapp.com',
    projectId: 'techrepair-glass',
    storageBucket: 'techrepair-glass.appspot.com',
    messagingSenderId: '772141911048',
    appId: '1:772141911048:web:c34b5cda2e34825a7555bd',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();

if (window.location.href.includes('http://localhost')) {
    auth.useEmulator('http://localhost:5002');
    functions.useEmulator('localhost', 5001);
    db.useEmulator('localhost', 5000);
}

export { firebase };
