/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../Assets/Images/logo.png';
import { functions } from '../../firebase';
import LoaderComponent from '../../Shared/Loader';
import { getConfirmation } from '../../utils/ui';

const OpenJobPage = ({ match, history }) => {
    const [jobDetail, setJobDetail] = useState<any>({});
    const [error, setError] = useState('');
    const [isJobOpen, setIsJobOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const techId = useMemo(() => (match.params ? match.params.techId : null), [match]);
    const regionId = useMemo(() => (match.params ? match.params.regionId : null), [match]);
    const jobId = useMemo(() => (match.params ? match.params.jobId : null), [match]);

    const checkIfAssignmentAvailable = useCallback(async () => {
        setShowLoader(true);
        try {
            const { data } = await functions.httpsCallable('checkIfAssignmentInviteStillAvailable')({
                jobId,
                techId,
                regionId,
            });
            setJobDetail(data.data.job);
            setIsJobOpen(data.data.open);
        } catch (errors) {
            setError(errors.message);
        }
        setShowLoader(false);
    }, [jobId, techId, regionId]);

    const assignCurrentTech = async () => {
        setShowLoader(true);
        try {
            await functions.httpsCallable('callDibsOnAssignmentInvite')({
                jobId,
                techId,
                regionId,
            });
            await getConfirmation(
                'Success! This job is now yours.',
                ' You should have received the details to your phone. Click Login to proceed.',
                {
                    showCancelButton: false,
                    confirmButtonText: 'Go to login',
                    cancelButtonText: '',
                },
            );
            history.push('/login');
        } catch (errors) {
            setError(errors.message);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        if (techId && regionId && jobId) {
            checkIfAssignmentAvailable();
        }
    }, [regionId, techId, jobId, checkIfAssignmentAvailable]);

    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500">
            <LoaderComponent showLoader={showLoader} />
            <div className="flex flex-col">
                <div className="mt-20 text-center mx-auto">
                    <img src={logo} alt="Logo" className="text-center mx-auto w-8/12" />
                    <p className="text-lg font-medium text-center text-white mt-4">Technician Scheduling Made Easy</p>
                </div>
                <div className="text-center mx-auto mt-8 bg-white w-full md:w-3/5 rounded shadow py-4 px-2">
                    {isJobOpen === true ? (
                        <>
                            <div className="uppercase text-xl font-medium px-3">
                                {jobDetail.status} {jobDetail.type} Job
                            </div>
                            <div className="text-3xl">
                                {jobDetail.vehicle?.year} {jobDetail.vehicle?.make} {jobDetail.vehicle?.model}
                            </div>
                            <div className="mt-4 text-base text-gray-600 text-center">
                                <p className="my-1">Insurance: {jobDetail.vehicle?.insurance}</p>
                                <p className="my-1">Contact: {jobDetail.contact}</p>
                                <p className="my-1">Date: {jobDetail.date}</p>
                                <p className="my-1">Status: {jobDetail.dropped}</p>
                                <p className="my-1">
                                    {jobDetail.waiting ? 'Available to pick up' : 'Not available to pick up'}
                                </p>
                                <p className="my-1">Notes: {jobDetail.notes}</p>
                            </div>
                            <div className="my-8">
                                <button
                                    type="button"
                                    onClick={() => assignCurrentTech()}
                                    className="text-lg bg-blue-500 text-white px-3 py-2 rounded w-72 capitalize"
                                >
                                    Assign yourself
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="py-4 bg-red-500 w-full text-white text-center mb-3">
                                {error ? <span>{error}</span> : 'Oh no, job is no longer available!'}
                            </div>
                            <div className="my-8">
                                <NavLink to="/login" className="text-lg bg-green-500 text-white px-3 py-2 rounded">
                                    Login
                                </NavLink>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default OpenJobPage;

OpenJobPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
